<template>
  <v-card class="mx-auto" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${loading ? '' : `(${table_data.length})`}`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip v-if="login.permissions.contacto_mensajes.create" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                x-small
                v-on="on"
                class="ml-1"
                color="success"
                :to="{ name: `${page_route}.store` }"
              >
                <v-icon v-text="'mdi-plus'" />
              </v-btn>
            </template>
            <span v-text="'Crear'" />
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" md="4">
          <v-select
            label="Estado"
            v-model="filter"
            :items="filter_options"
            item-value="id"
            :item-text="(v) => v.name"
            dense
            @change="getTableData"
          />
        </v-col>
        <v-col v-if="filter > 1" cols="12" xs="12" md="4">
          <v-autocomplete
            label="Tipo*"
            v-model="contact_message_type_id"
            :items="contact_message_types"
            item-value="id"
            :item-text="(v) => v.contact_message_type"
            :loading="contact_message_types_loading"
            dense
            @change="getTableData"
          />
        </v-col>
        <v-col cols="12" xs="12" md="4">
          <v-text-field
            v-model="table_search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-data-table
            :headers="table_headers"
            :search="table_search"
            :items="table_data"
            :loading="loading"
            dense
          >
            <template v-slot:item.action="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    x-small
                    v-on="on"
                    class="ml-1"
                    color="primary"
                    :to="{
                      name: `${page_route}.view`,
                      params: { id: item.id },
                    }"
                  >
                    <v-icon> mdi-eye-outline </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Ver'" />
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import { URL_API, headersToken } from "../../control";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    FaqDlg,
  },
  data() {
    return {
      page_route: "contacto_mensajes",
      login: this.$store.getters.getLogin,
      loading: false,
      table_data: [],
      table_search: "",
      table_headers: [
        {
          text: "Folio",
          filterable: true,
          value: "folio",
        },
        {
          text: "Fecha",
          filterable: true,
          value: "email_date",
        },
        {
          text: "Nombre",
          filterable: true,
          value: "full_name",
        },
        {
          text: "C. electrónico",
          filterable: true,
          value: "email",
        },
        {
          text: "Mensaje",
          filterable: true,
          value: "message",
        },
        {
          text: "ID SM",
          filterable: true,
          value: "enrollment",
        },
        {
          text: "Respuesta",
          filterable: true,
          value: "response",
        },
        {
          text: "Tipo",
          filterable: true,
          value: "contact_message_type.contact_message_type",
        },
        {
          text: "Subtipo",
          filterable: true,
          value: "contact_message_subtype.contact_message_subtype",
        },
        {
          text: "F. Termino",
          filterable: true,
          value: "discharged",
        },
        { text: "Acciones", value: "action", sortable: false, width: "104px" },
      ],
      filter: 1,
      filter_options: [
        { id: 1, name: "PENDIENTES" },
        { id: 2, name: "RESPONDIDOS" },
        { id: 3, name: "TERMINADOS" },
      ],
      contact_message_type_id: null,
      contact_message_types: [],
      contact_message_types_loading: true,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getTableData() {
      this.loading = true;

      this.contact_message_type_id =
        this.filter > 1 ? this.contact_message_type_id : null;

      Axios.get(
        URL_API +
          `/contact/messages?filter=${this.filter}&contact_message_type_id=${this.contact_message_type_id}`,
        headersToken(this.login.token)
      ).then((response) => {
        this.table_data = response.data.data;
        this.loading = false;
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    Axios.get(
      `${URL_API}/catalog?name=contact_message_types&no_order=false`,
      headersToken(this.login.token)
    ).then((resp) => {
      this.contact_message_types = resp.data.data;
      this.contact_message_types.push({
        id: null,
        contact_message_type: "TODOS",
      });
      this.contact_message_types_loading = false;
    });

    this.getTableData();
  },
};
</script>